<template>
	<div class="mt-20 md:mt-0">
		<!--------------------------- SUBHEADER------------------------------------------------------->
		<div
			class="flex items-center justify-between mb-4 text-white bg-verdedark w-full fixed md:static"
		>
			<div class="text-white font-bold my-2 ml-2 items-center">
				<span
					># Editar Orden de Compra:
					{{ this.$route.params.id.padStart(4, "0") }}</span
				>
			</div>
		</div>
		<!------------------------------------BODY----------------------------------------------------->

		<div
			class="mx-auto mb-10 flex items-center justify-center ml-72 md:ml-0 mt-12"
		>
			<div>
				<table class="table-auto">
					<thead>
						<tr>
							<td></td>
							<td class="border-2 px-4 py-2 font-bold text-center">Producto</td>
							<td class="border-2 px-4 py-2 font-bold text-center">Cantidad</td>
							<td class="border-2 px-4 py-2 font-bold text-center">Unidad</td>
							<td class="border-2 px-4 py-2 font-bold text-center">
								Proveedor
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(producto, k) in arrayOrdenCompra" :key="k">
							<td v-if="producto.cantidad_adquirida >= 0" class="pr-2">
								<svg
									@click="deleteRow(k)"
									class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
									width="26"
									height="26"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="delete_sweep_24px">
										<path
											id="icon/content/delete_sweep_24px"
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
										/>
									</g>
								</svg>
							</td>
							<td
								v-if="producto.cantidad_adquirida >= 0"
								class="border-2 px-4 py-2"
							>
								{{
									firstLetterUpperCase(producto.precios[0].producto.producto)
								}}
							</td>
							<td
								v-if="producto.cantidad_adquirida >= 0"
								class="border-2 px-4 py-2 text-center"
							>
								<input
									ref="cantidad"
									class="w-16 text-center appearance-none"
									type="number"
									@keypress.enter="nextRow(k)"
									v-model.lazy="producto.cantidad_adquirida"
								/>
							</td>
							<td
								v-if="producto.cantidad_adquirida >= 0"
								class="border-2 px-4 py-2 text-center"
							>
								{{ firstLetterUpperCase(producto.precios[0].unidad) }}
							</td>
							<td
								v-if="producto.cantidad_adquirida >= 0"
								class="border-2 px-4 py-2 text-center"
							>
								{{ firstLetterUpperCase(producto.precios[0].local[0].nombre) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="my-10 flex justify-center">
			<boton-primario botonpText="Guardar Cambios" @click="guardarCambios" />
		</div>
	</div>
</template>

<script>
import { formato, firstLetterUpperCase } from "@/functions.js";
import BotonPrimario from "../components/botonPrimario.vue";

export default {
	components: {
		BotonPrimario
	},
	data() {
		return {
			arrayOrdenCompra: []
		};
	},
	async mounted() {
		this.$store.state.isLoading = true;
		await this.ordenCompraPedidoQuery();
		this.$store.state.isLoading = false;
	},
	methods: {
		formato,
		firstLetterUpperCase,
		nextRow(index) {
			let newIndex = index + 1;
			if (this.arrayOrdenCompra.length <= newIndex) {
				newIndex = 0;
			}
			this.$refs.cantidad[newIndex].focus();
		},
		deleteRow(index) {
			this.arrayOrdenCompra[index].cantidad_adquirida = -1;
		},
		guardarCambios() {
			if (confirm("¿Guardar cambios?")) {
				this.guardarCambiosOrdenCompra();
			}
		},
		async guardarCambiosOrdenCompra() {
			let arrayResultOrdenCompra = [];
			this.arrayOrdenCompra.forEach(e => {
				arrayResultOrdenCompra.push({
					id: e.id,
					cantidad_adquirida: parseFloat(e.cantidad_adquirida),
					total_usd:
						parseFloat(e.cantidad_adquirida) *
						parseFloat(e.precios[0].precio_usd)
				});
			});
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/guardarOrdenCompraModificada.gql"),
					variables: {
						compras_id: parseInt(this.$route.params.id),
						pedido_actualizado: arrayResultOrdenCompra
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
					alert("Cambios guardados con exito. Redireccionando.");
					setTimeout(() => {
						this.$router.push({
							name: "PedidoID",
							params: { id: this.$route.params.id }
						});
					}, 1200);
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					alert("Error de comunicación, por favor vuelva a intentar.");
				});
		},
		async ordenCompraPedidoQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/productosOrdenCompraQuery.gql"),
					variables: {
						compras_id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.arrayOrdenCompra = data.data.productosOrdenCompraQuery;
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.idCurrentUsuario = "0";
					this.$store.state.isLoading = false;
				});
		}
	}
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>
